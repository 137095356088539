import React, { useEffect, useState } from 'react';
import { Layout, Menu, Dropdown, Button, Avatar, Modal, message } from 'antd';
import { UploadOutlined, HomeOutlined, FileOutlined, TeamOutlined, UserOutlined, PlusOutlined, BellOutlined } from '@ant-design/icons';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import PostJobScreen from './PostJobScreen';
import Dashboard from './Dashboard';
import JobManagement from '../../job-managment/JobManagement';
import JobDetails from '../../job-managment/JobDetails';
import CandidateDetail from '../../job-managment/CandidateDetails';
import OngoingEngagements from '../../job-managment/OngoingEngagements';
import CandidateAssignments from '../../job-managment/CandidateAssignments';

import Setting from './Organization'; // Import the new CreateOrgScreen page
import { useAuth } from '../../../AuthProvider';
import { getOrg } from '../../../api';
import logo from '../../../assets/images/bg/logo.svg';

const { Header, Sider, Content } = Layout;

const DashboardScreen = () => {
  const { currentUser, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [employerDetails, setEmployerDetails] = useState(null);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);

  useEffect(() => {
    const getEmployerDetails = async () => {
      try {
        const response = await getOrg();
        setEmployerDetails(response);
        // if (!response.logoUrl) {
        //   navigate('/dashboard/setting'); // Navigate to CreateOrgScreen if no logoUrl is found
        // }
      } catch (error) {
        setIsDetailModalVisible(true);
      }
    };

    getEmployerDetails();
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <Button type="link" onClick={logout}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  const selectedKey = () => {
    if (location.pathname.includes('/dashboard/post-job')) return 'create-assignment';
    if (location.pathname.includes('/dashboard/job-management')) return 'manage-assignments';
    if (location.pathname.includes('/dashboard/job-details') || location.pathname.includes('/dashboard/candidate-detail')) return 'manage-assignments';
    return 'dashboard';
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider style={{ padding: '10px', background: '#fff' }} width={250}>
        <div className="logo" style={{ height: '32px', margin: '16px', background: '#fff' }}>
          <h1 style={{ margin: 0, fontSize: '20px' }}>
            <img src={logo} style={{ height: '30px' }} />
          </h1>
        </div>
        <Menu theme="light" selectedKeys={[selectedKey()]} mode="inline" style={{ borderRight: 0 }}>
          <Menu.Item key="dashboard" icon={<HomeOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="create-assignment" icon={<PlusOutlined />}>
            <Link to="/dashboard/post-job">Create New Assignment</Link>
          </Menu.Item>
          <Menu.Item key="manage-assignments" icon={<FileOutlined />}>
            <Link to="/dashboard/job-management">Manage Assignments</Link>
          </Menu.Item>
          <Menu.Item key="candidate-applications" icon={<UserOutlined />}>
            <Link to="/dashboard/assignments">Candidate Applications</Link>
          </Menu.Item>
          <Menu.Item key="active-engagements" icon={<TeamOutlined />}>
            <Link to="/dashboard/ongoing-management">Ongoing Engagements</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: '0 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: '#fff',
          }}
        >
          <div></div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="text" style={{ padding: 0 }}>
                <Avatar style={{ backgroundColor: '#87d068', marginRight: 8, color: '#fff' }}>
                  {currentUser?.displayName?.[0]}
                </Avatar>
                <span>{currentUser?.displayName || currentUser?.email}</span>
              </Button>
            </Dropdown>
            <BellOutlined style={{ fontSize: '20px', marginLeft: '20px' }} />
          </div>
        </Header>
        <Content style={{}}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Routes>
              <Route path="post-job" element={<PostJobScreen />} />
              <Route path="job-management" element={<JobManagement />} />
              <Route path="job-details" element={<JobDetails />} />
              <Route path="candidate-detail" element={<CandidateDetail />} />
              <Route path="setting" element={<Setting organizationData={employerDetails} />} />
              <Route path="assignments" element={<CandidateAssignments organizationData={employerDetails} />} />
              <Route path="ongoing-management" element={<OngoingEngagements organizationData={employerDetails} />} />
              <Route path="/" element={<Dashboard />} />
            </Routes>
          </div>
        </Content>
      </Layout>

      <Modal
        title="Organization Details Required"
        visible={isDetailModalVisible}
        onOk={() => {
          setIsDetailModalVisible(false);
          navigate('/dashboard/setting')

        }}
        okText="Proceed"
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>We need additional details to proceed. Please complete the organization details.</p>
      </Modal>
    </Layout>
  );
};

export default DashboardScreen;
